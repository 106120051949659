.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 800px) {
  .header-mobile {
    display: none;
  }
}

main {
  flex-grow: 1;
  padding: 3rem 0;
  background-color: #282c34;
  color: #fff;
}