footer {
  padding: 1rem 0;
  background-color: #282c34;
  color: #ddd;

  .contact {
    text-align: center;

    a {
      margin-left: 0.5rem;
      color: #ddd;
    }
  }
}

.footer-spacer {
  min-height: 3rem;

  @media screen and (min-width: 800px) {
    display: none;
  }
}
