@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./Styles/Classes.scss";

* {
  font-family: "Montserrat";
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
  min-height: 100vh;

  #root {
    height: 100%;
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}
