header {
  background-color: #fff;
  color: #222;
}

.header-container {
  padding: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-left h3 {
    margin: 0;
  }

  .header-right {
    display: flex;
    justify-content: flex-end;

    ul {
      display: flex;

      li + li {
        margin-left: 1rem;
      }
    }
  }

  .link-social {
    color: #222;
  }

  nav {
    display: none;

    a {
      color: #222;
    }
  }

  @media screen and (min-width: 800px) {
    nav {
      display: block;
    }

    nav ul {
      display: flex;

      li + li {
        margin-left: 4rem;
      }
    }
  }
}
