.site-center {
    margin: auto;
    width: 90vw;
    
    @media screen and (min-width: 800px) {
        width: 80vw;
    };

    @media screen and (min-width: 1200px) {
        width: 60vw;
        max-width: 800px;
    }
}

.row {
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1200px) {
        flex-direction: row;
    };
}