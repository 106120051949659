aside {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;

  @media screen and (min-width: 1200px) {
    width: 15%;
  }

  @media screen and (min-width: 800px) {
    justify-content: flex-start;
  }

  .profile-pic {
    border-radius: 100%;
    overflow: hidden;
  }
}

article {
  font-size: 6vw;

  @media screen and (min-width: 480px) {
    font-size: 4vw;
  }

  @media screen and (min-width: 800px) {
    font-size: 2vw;
  }
  
  @media screen and (min-width: 1200px) {
    font-size: x-large;
    margin-left: 4vw;
  }

  p:first-child {
    margin-top: 0;
  }
}
