a {
  color: #fff;
}

.download-cv-container {
  display: flex;

  .download-cv {
    border: 1px solid #FFF;
    border-radius: 0.5rem;
    margin-left: auto;
    padding: 0.5rem 1rem;
  }
}

section + section {
  margin-top: 4rem;
}

.section-content {
  padding-left: 1rem;
}

.experience-details {
  padding-left: 1.5rem;
  text-indent: -1.5rem;
}

.highlight-toggle {
  margin-left: 0.5rem;
  text-decoration: underline;
}

.work-title {
  margin-bottom: 0;
  color: #ddd;
}

.work-years {
  color: #888;
}

.section-skills {
  ul {
    margin-left: 1rem;
    list-style: disc;
  }
}